import {
  AddProductSteps,
  AttributeForVariant,
  CrudProductStateTypeInformation,
  IProductMeta,
} from "./redcuer";
import { ApiAttribute, Category } from "@/types";
import {
  CRUD_PRODUCT_CHANGE_GENERAL_INFORMATION,
  CRUD_PRODUCT_CHANGE_STEP,
  CRUD_PRODUCT_DELETE_PRODUCT_IMAGES,
  CRUD_PRODUCT_INIT,
  CRUD_PRODUCT_LOADING,
  CRUD_PRODUCT_SELECT_CATEGORY,
  CRUD_PRODUCT_SET_ATTRIBUTE,
  CRUD_PRODUCT_SET_ATTRIBUTES,
  CRUD_PRODUCT_SET_ATTRIBUTES_FOR_VARIANTS,
  CRUD_PRODUCT_SET_ATTRIBUTE_FOR_VARIANTS,
  CRUD_PRODUCT_SET_PRODUCT_IMAGES,
  CRUD_PRODUCT_SET_PRODUCT_MEDIAS,
  CRUD_PRODUCT_SET_SELECTED_CATEGORIES,
  CRUD_PRODUCT_SET_VARIANTS,
  CRUD_PRODUCT_META,
} from "./types";

import { CategoryWithAttributes } from "@/pages/dashboard/products/categories/edit";
import { IEntityMedia } from "./services";
import { ProductVariant } from "@/pages/dashboard/products/add-product/variants-pricing";

const resetCrudProduct = () => ({
  type: CRUD_PRODUCT_INIT,
});

const changeGeneralInformation = (value: CrudProductStateTypeInformation) => ({
  type: CRUD_PRODUCT_CHANGE_GENERAL_INFORMATION,
  payload: {
    value,
  },
});

const changeStep = (step: AddProductSteps) => {
  return {
    type: CRUD_PRODUCT_CHANGE_STEP,
    payload: step,
  };
};

const toggleCategory = (category: Category) => {
  return {
    type: CRUD_PRODUCT_SELECT_CATEGORY,
    payload: category,
  };
};

const changeAttributeValue = (
  attributeId: string,
  attributeValue: string | string[]
) => {
  return {
    type: CRUD_PRODUCT_SET_ATTRIBUTE,
    payload: {
      id: attributeId,
      value: attributeValue,
    },
  };
};

const setAttributes = (attributes: { [key: number]: string[] }) => {
  return {
    type: CRUD_PRODUCT_SET_ATTRIBUTES,
    payload: attributes,
  };
};

const setAttributeForVariants = (attributeForVariant: any) => {
  return {
    type: CRUD_PRODUCT_SET_ATTRIBUTE_FOR_VARIANTS,
    payload: attributeForVariant,
  };
};

const setAttributesForVariants = (attributes: { [key: number]: string[] }) => {
  return {
    type: CRUD_PRODUCT_SET_ATTRIBUTES_FOR_VARIANTS,
    payload: attributes,
  };
};

const setVariants = (variants: ProductVariant[]) => {
  return {
    type: CRUD_PRODUCT_SET_VARIANTS,
    payload: variants,
  };
};

const setMetas = (metas: IProductMeta) => {
  return {
    type: CRUD_PRODUCT_META,
    payload: metas,
  };
};

const setSelectedCategories = (categories: CategoryWithAttributes[]) => {
  return {
    type: CRUD_PRODUCT_SET_SELECTED_CATEGORIES,
    payload: categories,
  };
};

const setProductImages = (productImages: (string | File | null)[]) => {
  return {
    type: CRUD_PRODUCT_SET_PRODUCT_IMAGES,
    payload: productImages,
  };
};

const setProductMedias = (productMedias: IEntityMedia[]) => {
  return {
    type: CRUD_PRODUCT_SET_PRODUCT_MEDIAS,
    payload: productMedias,
  };
};

const deleteProductImage = (key: number) => {
  return {
    type: CRUD_PRODUCT_DELETE_PRODUCT_IMAGES,
    payload: key,
  };
};

const disableLoading = () => {
  return {
    type: CRUD_PRODUCT_LOADING,
  };
};

const crudProductActions = {
  changeGeneralInformation,
  changeStep,
  toggleCategory,
  changeAttributeValue,
  setAttributes,
  setAttributeForVariants,
  setAttributesForVariants,
  setVariants,
  setSelectedCategories,
  resetCrudProduct,
  setProductImages,
  setProductMedias,
  deleteProductImage,
  disableLoading,
  setMetas,
};

export default crudProductActions;
