import {
  ApiAttributeWithPivot,
  CategoryWithAttributes
} from "./../../pages/dashboard/products/categories/edit/index"

import { CrudProductDispatchTypes } from "./types"
import { IEntityMedia } from "./services"
import { IProductBrand } from "@/types/product"
import { ProductVariant } from "@/pages/dashboard/products/add-product/variants-pricing"
import { Reducer } from "redux"

// import { ApiAttributeWithPivot, CategoryWithAttributes } from "@/types/product"

type CrudProductFieldValue = string | null

export type CrudProductStateTypeInformation = {
  brand_id: number | null
  local_title: CrudProductFieldValue
  en_title: CrudProductFieldValue
  description: CrudProductFieldValue
  is_original: boolean
}

export interface CrudProductVariant {
  attributables: number[]
  regular_price: number
  sell_price: number
  stock_quantity: number
  is_active: boolean
}

export interface AttributeForVariant {
  attribute: ApiAttributeWithPivot
  items: any
}

export interface VariantImage {
  attribute: ApiAttributeWithPivot
  images: string[]
}

export type IProductMeta = { [key: string]: string }

export type CrudProductStateType = {
  step: AddProductSteps
  information: CrudProductStateTypeInformation
  categories: CategoryWithAttributes[]
  attributes: { [key: string]: any }
  attributes_for_variants: { [key: string]: AttributeForVariant }
  variants: ProductVariant[]
  variants_image: (string | File | null)[]
  variants_medias: IEntityMedia[]
  loading: boolean
  new_image: boolean
  meta?: IProductMeta | null
}

export enum AddProductSteps {
  GeneralInformation,
  ProductGroupTypes,
  ProductFeatures,
  ProductFeaturesVariantMakers,
  Images,
  Pricing
}

export enum AddGuaranteeCodeSteps {
  AddGuaranteeCodeInformation,
  GroupRelease
}

const initial: CrudProductStateType = {
  step: AddProductSteps.GeneralInformation,
  information: {
    brand_id: null,
    local_title: "",
    en_title: "",
    description: "",
    is_original: false
  },
  categories: [],
  attributes: {},
  attributes_for_variants: {},
  variants: [],
  variants_image: [],
  variants_medias: [],
  loading: true,
  new_image: false
}

const reducer: Reducer<CrudProductStateType, CrudProductDispatchTypes> = (
  state = initial,
  action
) => {
  switch (action.type) {
    case "CRUD_PRODUCT_INIT":
      return initial
    case "CRUD_PRODUCT_META":
      return {
        ...state,
        meta: {
          ...state.meta,
          ...action.payload
        }
      }
    case "CRUD_PRODUCT_LOADING":
      return {
        ...state,
        new_image: false,
        loading: false
      }
    case "CRUD_PRODUCT_DELETE_PRODUCT_IMAGES":
      return {
        ...state,
        variants_image: state.variants_image.filter(
          (item, index) => index !== action.payload
        )
      }
    case "CRUD_PRODUCT_SET_PRODUCT_IMAGES":
      return {
        ...state,
        variants_image: action.payload,
        new_image: true
      }
    case "CRUD_PRODUCT_DELETE_PRODUCT_IMAGES":
      return {
        ...state,
        index: action.payload
      }
    case "CRUD_PRODUCT_SET_PRODUCT_MEDIAS":
      return {
        ...state,
        variants_medias: action.payload
      }
    case "CRUD_PRODUCT_SET_ATTRIBUTE":
      return {
        ...state,
        attributes: {
          ...state.attributes,
          [action.payload.id]: action.payload.value
        }
      }
    case "CRUD_PRODUCT_SET_ATTRIBUTE":
      return {
        ...state,
        attributes: {
          ...state.attributes,
          [action.payload.id]: action.payload.value
        }
      }
    case "CRUD_PRODUCT_SET_ATTRIBUTES":
      return {
        ...state,
        attributes: action.payload
      }
    case "CRUD_PRODUCT_SET_ATTRIBUTE_FOR_VARIANTS":
      const payloadAttribute = action.payload.attribute
      return {
        ...state,
        attributes_for_variants: {
          ...state.attributes_for_variants,
          [payloadAttribute.id]: {
            attribute: payloadAttribute,
            items: action.payload.items
          }
        }
      }
    case "CRUD_PRODUCT_SET_ATTRIBUTES_FOR_VARIANTS":
      return {
        ...state,
        attributes_for_variants: action.payload
      }
    case "CRUD_PRODUCT_SET":
      return {
        ...state,
        information: {
          ...state.information,
          local_title: action.payload.value.name,
          en_title: action.payload.value.name,
          is_original: Boolean(action.payload.value.is_original),
          description: action.payload.value.description
        }
      }
    case "CRUD_PRODUCT_SET_SELECTED_CATEGORIES":
      return {
        ...state,
        categories: action.payload
      }
    case "CRUD_PRODUCT_SELECT_CATEGORY":
      //Flag for check if this category is exist
      const newCateogryId = action.payload.id
      for (let item of state.categories) {
        if (newCateogryId === item.id) {
          return {
            ...state,
            categories: state.categories.filter(
              (categoryItem) => categoryItem.id !== newCateogryId
            )
          }
        }
      }

      return {
        ...state,
        categories: [...state.categories, action.payload]
      }
    case "CRUD_PRODUCT_CHANGE_GENERAL_INFORMATION":
      return {
        ...state,
        information: action.payload.value
      }
    case "CRUD_PRODUCT_CHANGE_STEP":
      return { ...state, step: action.payload }
    case "CRUD_PRODUCT_SET_VARIANTS":
      return { ...state, variants: action.payload }
    default:
      return state
  }
}

export default reducer
