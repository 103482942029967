import * as React from "react"

import { FModal } from "@/components/reusables/modal"

interface IGlobalContext {
  showModal: (element: React.ReactNode) => void
  closeModal: () => void
}

const initialState: IGlobalContext = {
  showModal: (element: React.ReactNode) => {},
  closeModal: () => {}
}

const GlobalContext = React.createContext(initialState)

export const useGlobalContext = () => React.useContext(GlobalContext)

const GContext: React.FC = ({ children }) => {
  const [isShowModal, setShowModal] = React.useState(false)
  const [modalContext, setModalContent] = React.useState<React.ReactNode>(null)
  const showModal = (element: React.ReactNode) => {
    setModalContent(element)
    setShowModal(true)
  }
  const closeModal = () => setShowModal(false)

  return (
    <GlobalContext.Provider
      value={{
        showModal,
        closeModal
      }}
    >
      <FModal open={isShowModal} handleClose={closeModal}>
        {modalContext}
      </FModal>
      {children}
    </GlobalContext.Provider>
  )
}
export default GContext
