import { GUARANTEE_ADD, GUARANTEE_REMOVE } from "./types"

const addGuarantee = (value: any) => ({
  type: GUARANTEE_ADD,
  payload: value
})

const removeGuarantee = (value: number) => ({
  type: GUARANTEE_REMOVE,
  payload: value
})

const crudProductActions = {
  addGuarantee,
  removeGuarantee
}

export default crudProductActions
