import {
  ADD_NEW_CATEGORY,
  CAETGORIES_ADD_CHILDREN,
  CAETGORIES_DELETING,
  CATEGORIES_GO_BACK,
  CATEGORIES_GO_FORWARD,
  CATEGORIES_LOADING,
  SELECTED_CATEGORY,
  UNSELECT_CATEGORY,
  UPDATE_CATEGORIES
} from "./types"

import { Category } from "@/types"

const updateCategories = (value: Category[]) => ({
  type: UPDATE_CATEGORIES,
  payload: value
})

const loadingCategories = () => ({
  type: CATEGORIES_LOADING
})

const addNewCategory = (value: Category) => ({
  type: ADD_NEW_CATEGORY,
  payload: value
})

const selectCategory = (value: Category) => ({
  type: SELECTED_CATEGORY,
  payload: value
})

const unselectCategory = () => ({
  type: UNSELECT_CATEGORY
})

const categoriesGoBack = () => ({
  type: CATEGORIES_GO_BACK
})

const categoriesGoForward = () => ({
  type: CATEGORIES_GO_FORWARD
})

const deleteCategory = (category: Category) => ({
  type: CAETGORIES_DELETING,
  payload: category
})

const addCategory = (targetCategory: Category, child: Category) => ({
  type: CAETGORIES_ADD_CHILDREN,
  payload: {
    targetCategory,
    child
  }
})

const authLoading = (payload: boolean) => ({
  type: CATEGORIES_LOADING,
  payload
})

const categoriesActions = {
  updateCategories,
  addNewCategory,
  selectCategory,
  unselectCategory,
  categoriesGoBack,
  categoriesGoForward,
  deleteCategory,
  addCategory,
  loadingCategories,
  authLoading
}

export default categoriesActions
