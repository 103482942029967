import { CategoriesDispatchTypes } from "./types"
import { Category } from "@/types"
import { Reducer } from "redux"

export type CategoriesStateType = {
  items: Category[]
  selected: Category | null
  loading: boolean
}

const initial: CategoriesStateType = {
  items: [],
  selected: null,
  loading: false
}

const removeCategoryFromItems = (items: Category[], item: Category) => {
  let categoryItems: Category[] = []
  for (let category of items) {
    const targetItem = category

    let tmpItem = null

    if (category.id !== item.id) {
      tmpItem = {}

      tmpItem = {
        ...targetItem,
        children: undefined
      }
      if (category.children) {
        tmpItem = {
          ...tmpItem,
          children: removeCategoryFromItems(category.children, item)
        }
      }
    }
    if (Boolean(tmpItem)) categoryItems.push(tmpItem as Category)
  }
  return categoryItems
}

const addChildren = (
  items: Category[],
  targetItem: Category,
  child: Category
) => {
  let categoryItems: Category[] = []
  for (let category of items) {
    let tmpItem = category

    if (category.id === targetItem.id) {
      if (category.children) {
        tmpItem = {
          ...targetItem,
          children: [...category.children, child]
        }
      } else {
        tmpItem = {
          ...targetItem,
          children: [child]
        }
      }
    }

    if (category.children) {
      console.log("targetItem", targetItem, child)
      tmpItem = {
        ...category,
        children: [
          ...category.children,
          ...addChildren(category.children, category, child)
        ]
      }
    }

    categoryItems.push(tmpItem as Category)
  }
  return categoryItems
}

const searchSiblings = (
  categoryItems: Category[],
  item: Category,
  type: "prev" | "next" = "prev"
): Category | null => {
  for (let index in categoryItems) {
    const category = categoryItems[index]
    let targetCategory = null
    if (type === "prev") {
      targetCategory = categoryItems?.[Number(index) - 1] || null
    } else if (type === "next") {
      targetCategory = categoryItems?.[Number(index) + 1] || null
    }
    if (category.id === item.id) {
      return targetCategory
    }
    // return searchSiblings(category.children, item, type)
    // if (category.children) {
    //   return searchSiblings(category.children, item, type)
    // } else if (category.id === item.id) {
    //   return targetCategory
    // }
  }
  return null
}

const collectTheParents = (
  categoryItems: Category[],
  item: Category,
  parent: Category | null = null
): Category[] => {
  let output: Category[] = []
  for (let category of categoryItems) {
    if (category.id === item.id) {
      if (parent != null) {
        output.push(parent)
      }
    } else if (category.children && category.children.length) {
      output = [
        ...output,
        ...collectTheParents(category.children, item, category)
      ]
    }
  }
  return output
}

export const searchCategoryParents = (
  categoryItems: Category[],
  item: Category
): Category[] => {
  let parents: Category[] = []
  for (let index in categoryItems) {
    const category = categoryItems[index]
    if (category.children && category.children.length) {
      parents = [
        ...parents,
        ...collectTheParents(category.children, item, category)
      ]
    }
  }
  return parents
}

export const findPrevCategories = (
  items: Category[],
  selected: Category | null
): Category | null => {
  if (!selected) return null
  return searchSiblings(items, selected, "prev")
}

export const findNextCategories = (
  items: Category[],
  selected: Category | null
): Category | null => {
  if (!selected) return null
  return searchSiblings(items, selected, "next")
}

export const hasCategoryParents = (
  items: Category[],
  selected: Category
): Category | null => {
  const foundedParents = searchCategoryParents(items, selected)
  return foundedParents.length > 0 ? foundedParents[0] : null
}

const reducer: Reducer<CategoriesStateType, CategoriesDispatchTypes> = (
  state = initial,
  action
) => {
  switch (action.type) {
    case "CATEGORIES_LOADING":
      return { ...state, loading: action.payload }
    case "UPDATE_CATEGORIES":
      return { ...state, items: action.payload }
    case "ADD_NEW_CATEGORY":
      return { ...state, items: [...state.items, action.payload] }
    case "UNSELECT_CATEGORY":
      return {
        ...state,
        selected: null
      }
    case "SELECTED_CATEGORY":
      return {
        ...state,
        selected:
          state.selected === null || state.selected.id !== action.payload.id
            ? action.payload
            : null
      }
    case "CATEGORIES_GO_BACK":
      return {
        ...state,
        selected: findPrevCategories(state.items, state.selected)
      }
    case "CATEGORIES_GO_FORWARD":
      return {
        ...state,
        selected: findNextCategories(state.items, state.selected)
      }
    case "CAETGORIES_DELETING":
      return {
        ...state,
        items: removeCategoryFromItems(state.items, action.payload)
      }
    case "CAETGORIES_ADD_CHILDREN":
      return {
        ...state,
        items: addChildren(
          state.items,
          action.payload.targetCategory,
          action.payload.child
        )
      }
    default:
      return state
  }
}

export default reducer
