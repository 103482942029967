import { Category } from "@/types"

export const UPDATE_CATEGORIES = "UPDATE_CATEGORIES"

interface UpdateCategories {
  type: typeof UPDATE_CATEGORIES
  payload: Category[]
}

export const ADD_NEW_CATEGORY = "ADD_NEW_CATEGORY"

interface AddNewCategory {
  type: typeof ADD_NEW_CATEGORY
  payload: Category
}
export const UNSELECT_CATEGORY = "UNSELECT_CATEGORY"
interface UnSelectCategory {
  type: typeof UNSELECT_CATEGORY
}

export const SELECTED_CATEGORY = "SELECTED_CATEGORY"
interface SelecteCategory {
  type: typeof SELECTED_CATEGORY
  payload: Category
}

export const CATEGORIES_GO_BACK = "CATEGORIES_GO_BACK"
interface CategoriesGoBack {
  type: typeof CATEGORIES_GO_BACK
}

export const CATEGORIES_GO_FORWARD = "CATEGORIES_GO_FORWARD"
interface CategoriesGoForward {
  type: typeof CATEGORIES_GO_FORWARD
}

export const CAETGORIES_DELETING = "CAETGORIES_DELETING"
interface CategoriesDeleting {
  type: typeof CAETGORIES_DELETING
  payload: Category
}

export const CAETGORIES_ADD_CHILDREN = "CAETGORIES_ADD_CHILDREN"
interface CategoriesAddChildren {
  type: typeof CAETGORIES_ADD_CHILDREN
  payload: {
    targetCategory: Category
    child: Category
  }
}

export const CATEGORIES_LOADING = "CATEGORIES_LOADING"
interface CategoriesLoading {
  type: typeof CATEGORIES_LOADING
  payload: boolean
}

export type CategoriesDispatchTypes =
  | UpdateCategories
  | AddNewCategory
  | SelecteCategory
  | UnSelectCategory
  | CategoriesGoBack
  | CategoriesGoForward
  | CategoriesDeleting
  | CategoriesAddChildren
  | CategoriesLoading
