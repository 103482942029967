import VazirBlack from "@/assets/fonts/vazir/Vazir-Black-FD.woff2"
import VazirBold from "@/assets/fonts/vazir/Vazir-Bold-FD.woff2"
import VazirMedium from "@/assets/fonts/vazir/Vazir-Medium-FD.woff2"
import VazirRegular from "@/assets/fonts/vazir/Vazir-Regular-FD.woff2"
import VazirThin from "@/assets/fonts/vazir/Vazir-Thin-FD.woff2"

/**
 * Since creating all font-faces takes lots of code lines, i placed them here for code organization.
 */
const CssBaselineOverrides = {
  styleOverrides: `
    body {
      direction: ltr;
    }
    @font-face {
      font-family: 'Vazir';
      src: url(${VazirBlack}) format('woff');
      font-weight: 900;
    }
    
    @font-face {
      font-family: 'Vazir';
      src: url(${VazirBold}) format('woff');
      font-weight: Bold;
    }
    @font-face {
      font-family: 'Vazir';
      src: url(${VazirMedium}) format('woff');
      font-weight: 500;
    }

    @font-face {
      font-family: 'Vazir';
      src: url(${VazirRegular}) format('woff');
      font-weight: 400;
    }

    @font-face {
      font-family: 'Vazir';
      src: url(${VazirThin}) format('woff');
      font-weight: 300;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }

    a {
      text-decoration: none;
    }

    /* React toastify style overrides */

    .Toastify__toast-body {
      height: 4.5rem;
    }        
    .MuiLoadingButton-loading > svg {
      opacity: 0;
    }
  `
}

export default CssBaselineOverrides
