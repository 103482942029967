export const GUARANTEE_ADD = "GUARANTEE_ADD"
interface GuaranteeAdd {
  type: typeof GUARANTEE_ADD
  payload: any
}

export const GUARANTEE_REMOVE = "GUARANTEE_REMOVE"
interface GuaranteeRemove {
  type: typeof GUARANTEE_REMOVE
  payload: any
}

export type GuaranteeDispatchTypes = GuaranteeAdd | GuaranteeRemove
