import { authReducer } from "./auth"
import { categoriesReducer } from "./categories"
import { combineReducers } from "redux"
import { crudProductReducer } from "./crud-product"
import { guaranteeReducer } from "./guarantees"

const rootReducer = combineReducers({
  auth: authReducer,
  crudProduct: crudProductReducer,
  categories: categoriesReducer,
  guarantees: guaranteeReducer
})

export default rootReducer
/* will add persist configs here */
