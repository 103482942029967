/**
 * Place all route names here
 * @example ```js
 * {
 *     "assets": "/assets",
 *     "assetDetails": "/assets/:id"
 * }
 * ```
 */

const routes = {
  login: "/login",
  dashboard: "/dashboard",
  guaranteesCode: {
    index: "guarantees-code",
    add: "add-guarantee-code",
    edit: "edit/:id"
  },
  excel: {
    index: "excel",
    products: "products/:id",
    customers: "customers/:id",
    tags: "tags/:id",
    orders: "orders/:id"
  },
  products: {
    add: "add-product",
    show: "show/:id",
    edit: "edit/:id",
    index: "products",
    categories: {
      index: "categories",
      edit: "edit/:id"
    },
    attributes: "attributes",
    tags: {
      index: "tags",
      show: "show/:id",
      add: "add-tag"
    },
    coupons: {
      index: "coupons",
      show: "show/:id",
      add: "add-coupon"
    },
    packing: {
      index: "packing",
      show: "show/:id",
      add: "add-packing"
    },
    shipping_ways: {
      index: "shipping_ways",
      show: "show/:id",
      add: "add"
    },
    comments: "comments",
    brands: {
      index: "brands",
      add: "add-brand",
      show: "show/:id",
      edit: "edit/:id"
    }
  },
  orders: {
    index: "orders",
    edit: "edit/:id",
    show: "show/:id"
  },
  health: {
    index: "health"
  },
  customers: {
    index: "customers",
    show: "show/:id"
  },
  notifications: {
    index: "notifications",
    show: "show/:id",
    add: "add"
  },
  users: {
    index: "users",
    add: "add",
    show: "show/:id"
  },
  illnesses: {
    index: "illnesses",
    list: "list",
    add: "add-illness",
    show: "show/:id",
    categories: {
      index: "categories",
      edit: "edit/:id"
    },
    tags: {
      index: "tags",
      show: "show/:id",
      add: "add-tag"
    }
  },
  categories: {
    add: "add-category",
    show: "show/:id",
    index: "categories"
  },
  packing_products: {
    index: "packing"
  },
  blog: {
    index: "blog",
    add: "add-article",
    show: "show/:id",
    list: "list",
    categories: {
      index: "categories",
      edit: "edit/:id"
    },
    tags: {
      index: "tags",
      show: "show/:id",
      add: "add-tag"
    },
    comments: "comments"
  },
  attributes: {
    index: "attributes",
    add: "add-attribute",
    show: "show/:id"
  },
  supports: {
    index: "supports"
  },
  associates: {
    index: "associates",
    add: "add",
    show: "show/:id",
    cities: "cities"
  },
  settings: {
    index: "settings",
    home: {
      index: "home"
    },
    roles: {
      index: "roles",
      add: "add",
      show: "show/:id"
    },
    privileges: {
      index: "privileges",
      add: "add",
      show: "show/:id"
    }
  }
}

export default routes
