import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import React, { useEffect } from "react";

import { IPostTypeMeta } from "@/pages/dashboard/post-type/add";
import LazyRoute from "./LazyRoute";
import LoadingIndicatorContainer from "@/components/normal/loading-indicator";
import PrivateRoute from "./PrivateRoute";
import RouteNames from "./routeNames";
import { authSelectors } from "@/redux/auth";
import { authServices } from "@/redux/services";
import { t } from "i18next";
import { useAppSelector } from "@/redux";
import { useDispatch } from "react-redux";

/**
 * All routes are placed here. If you wish to lazy load a page, refer to the example below
 * @example ```jsw
 * const UserDashboard = React.lazy(() => import("../panels/user/pages/Dashboard/index.tsx"))
 * .
 * .
 * .
 * <Route path="dashboard" element={<LazyRoute element={<UserDashboard />} />} />
 * ```
 * **Important note:**
 * You must export default the page and import that default export with `React.lazy` in order for lazy loading to work.
 */

const Login = React.lazy(() => import("@/pages/login-page"));

//Dashboard
const Dashboard = React.lazy(() => import("@/pages/dashboard"));

//Products pages
const Products = React.lazy(() => import("@/pages/dashboard/products"));
const AddProduct = React.lazy(
  () => import("@/pages/dashboard/products/add-product")
);
const CommentsPage = React.lazy(
  () => import("@/pages/dashboard/products/comments")
);

//Blog pages
const Blog = React.lazy(() => import("@/pages/dashboard/blogs"));
const PostTypeAdd = React.lazy(() => import("@/pages/dashboard/post-type/add"));

//Coupons
const CouponsPage = React.lazy(
  () => import("@/pages/dashboard/products/coupons")
);
const CouponSinglePage = React.lazy(
  () => import("@/pages/dashboard/products/coupons/add-coupon")
);

//GuaranteesCode
const GuaranteesCode = React.lazy(
  () => import("@/pages/dashboard/guarantees-code")
);
const AddGuaranteeCode = React.lazy(
  () => import("@/pages/dashboard/guarantees-code/add-guarantee-code")
);
const EditGuaranteeCode = React.lazy(
  () => import("@/pages/dashboard/guarantees-code/add-guarantee-code")
);

//Supports
const SupportsListPage = React.lazy(
  () => import("@/components/pages/supports/list")
);

//Associates
const AssociatesListPage = React.lazy(
  () => import("@/components/pages/associates/list")
);

//Coupons
const NotificationsPage = React.lazy(
  () => import("@/pages/dashboard/notifications")
);
const AddNotification = React.lazy(
  () => import("@/pages/dashboard/notifications/add")
);

//Attributes pages
const Attributes = React.lazy(
  () => import("@/pages/dashboard/products/attributes")
);
const AddAttribute = React.lazy(
  () => import("@/pages/dashboard/products/attributes/add-attribute")
);

//Product Categories Page
const EntityCategoriesPage = React.lazy(
  () => import("@/pages/dashboard/products/categories")
);
const EditCategoryPage = React.lazy(
  () => import("@/pages/dashboard/products/categories/edit")
);
const ProductPacking = React.lazy(
  () => import("@/pages/dashboard/products/packing")
);
const SinglePackingProduct = React.lazy(
  () => import("@/pages/dashboard/products/packing/add-packing")
);

//Brands Pages
const ProductBrands = React.lazy(
  () => import("@/pages/dashboard/products/brands")
);
const ProductBrandSingle = React.lazy(
  () => import("@/pages/dashboard/products/brands/add-brand")
);

//Excel Pages
const ProductsExcelImports = React.lazy(
  () => import("@/pages/dashboard/excel/products")
);

//Settings Page
const SettingsRolesPage = React.lazy(
  () => import("@/pages/dashboard/settings/roles")
);
const SettingsRoleAddPage = React.lazy(
  () => import("@/pages/dashboard/settings/roles/add")
);
const SettingsHomePage = React.lazy(
  () => import("@/pages/dashboard/settings/home/index")
);

//Settings Users
const SettingsPriviligesUser = React.lazy(
  () => import("@/pages/dashboard/settings/priviliges-user")
);
const SettingsPriviligesUserAddPage = React.lazy(
  () => import("@/pages/dashboard/settings/priviliges-user/add")
);

//Illnesses Page
const IllnessesPage = React.lazy(() => import("@/pages/dashboard/illnesses"));

//Orders Page
const OrdersPage = React.lazy(() => import("@/pages/dashboard/orders"));
const EditSingleOrder = React.lazy(
  () => import("@/pages/dashboard/orders/edit/index")
);

const ShowOrderPage = React.lazy(() => import("@/pages/dashboard/orders/show"));
//Customers Page
const CustomersPage = React.lazy(() => import("@/pages/dashboard/customers"));
const CustomerSinglePage = React.lazy(
  () => import("@/pages/dashboard/customers/show")
);

//TagsPages
const TagsPage = React.lazy(() => import("@/pages/dashboard/products/tags"));
const TagsSinglePage = React.lazy(
  () => import("@/pages/dashboard/products/tags/add-tag")
);
//ShippingPages

const ProductShippingWays = React.lazy(
  () => import("@/pages/dashboard/products/shipping-ways")
);
const ProductShippingWaysSingle = React.lazy(
  () => import("@/pages/dashboard/products/shipping-ways/show")
);

const associateMetaFields: IPostTypeMeta[] = [
  {
    type: "text-field",
    field: "phone-number",
    label: t("common:tell"),
    name: "phone-number",
  },
];

const blogMetaFields: IPostTypeMeta[] = [
  {
    type: "text-field",
    field: "ref-link",
    label: t("common:ref_link"),
    name: "ref-link",
  },
  {
    type: "text-area",
    field: "embed-video",
    label: t("common:embed_video"),
    name: "embed-video",
  },
];

const RouterConfig = () => {
  const {
    login,
    products,
    orders,
    customers,
    notifications,
    dashboard,
    blog,
    attributes,
    settings,
    illnesses,
    excel,
    guaranteesCode,
    supports,
    associates,
  } = RouteNames;

  const isLoadingAuth = useAppSelector(authSelectors.loadingSelector);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(authServices.getUserProfile());
  }, []);

  return isLoadingAuth ? (
    <LoadingIndicatorContainer fullScreen type='circular' />
  ) : (
    <Routes>
      <Route path={login} element={<LazyRoute element={<Login />} />} />
      <Route path={"/"} element={<Navigate to={dashboard} />} />
      <Route
        path={dashboard}
        element={<PrivateRoute element={<Outlet />} role='super-admin' />}
      >
        <Route index element={<LazyRoute element={<Dashboard />} />} />
        {/** Guarrantees */}
        <Route path={guaranteesCode.index}>
          <Route index element={<LazyRoute element={<GuaranteesCode />} />} />
          <Route
            path={guaranteesCode.add}
            element={<LazyRoute element={<AddGuaranteeCode />} />}
          />
          <Route
            path={guaranteesCode.edit}
            element={<LazyRoute element={<EditGuaranteeCode />} />}
          />
        </Route>
        {/** Guarrantees */}
        {/** Supports */}
        <Route path={supports.index}>
          <Route index element={<LazyRoute element={<SupportsListPage />} />} />
        </Route>
        {/** Supports */}
        {/** Associates */}
        <Route path={associates.index}>
          <Route
            index
            element={<LazyRoute element={<AssociatesListPage />} />}
          />
          <Route
            path={associates.add}
            element={
              <LazyRoute
                element={
                  <PostTypeAdd
                    pageType='associates'
                    metaField={associateMetaFields}
                  />
                }
              />
            }
          />
          <Route
            path={associates.show}
            element={
              <LazyRoute
                element={
                  <PostTypeAdd
                    pageType='associates'
                    metaField={associateMetaFields}
                  />
                }
              />
            }
          />
          <Route
            path={associates.cities}
            element={
              <LazyRoute
                permission='categories'
                element={<EntityCategoriesPage type='associates' />}
              />
            }
          />
        </Route>
        {/** Associates */}
        {/** Products */}
        <Route path={products.index}>
          <Route
            index
            element={<LazyRoute permission='products' element={<Products />} />}
          />
          <Route
            path={products.add}
            element={
              <LazyRoute permission='products' element={<AddProduct />} />
            }
          />
          <Route
            path={products.show}
            element={
              <LazyRoute permission='products' element={<AddProduct />} />
            }
          />
          <Route
            path={products.edit}
            element={
              <LazyRoute permission='products' element={<AddProduct />} />
            }
          />
          <Route path={products.tags.index}>
            <Route
              index
              element={
                <LazyRoute
                  permission='tags'
                  element={<TagsPage postType={"product"} />}
                />
              }
            />
            <Route
              path={products.tags.add}
              element={
                <LazyRoute permission='tags' element={<TagsSinglePage />} />
              }
            />
            <Route
              path={products.tags.show}
              element={
                <LazyRoute permission='tags' element={<TagsSinglePage />} />
              }
            />
          </Route>
          <Route path={products.coupons.index}>
            <Route
              index
              element={
                <LazyRoute permission='coupons' element={<CouponsPage />} />
              }
            />
            <Route
              path={products.coupons.add}
              element={
                <LazyRoute
                  permission='coupons'
                  element={<CouponSinglePage />}
                />
              }
            />
            <Route
              path={products.coupons.show}
              element={
                <LazyRoute
                  permission='coupons'
                  element={<CouponSinglePage />}
                />
              }
            />
          </Route>
          <Route path={products.packing.index}>
            <Route index element={<LazyRoute element={<ProductPacking />} />} />
            <Route
              path={products.packing.add}
              element={<LazyRoute element={<SinglePackingProduct />} />}
            />
            <Route
              path={products.packing.show}
              element={<LazyRoute element={<SinglePackingProduct />} />}
            />
          </Route>
          <Route path={products.brands.index}>
            <Route
              index
              element={
                <LazyRoute permission='brands' element={<ProductBrands />} />
              }
            />
            <Route
              path={products.brands.add}
              element={
                <LazyRoute
                  permission='brands'
                  element={<ProductBrandSingle />}
                />
              }
            />
            <Route
              path={products.brands.show}
              element={
                <LazyRoute
                  permission='brands'
                  element={<ProductBrandSingle />}
                />
              }
            />
          </Route>
          <Route path={products.attributes}>
            <Route
              index
              element={
                <LazyRoute permission='attributes' element={<Attributes />} />
              }
            />
            <Route
              path={attributes.add}
              element={
                <LazyRoute permission='attributes' element={<AddAttribute />} />
              }
            />
            <Route
              path={attributes.show}
              element={
                <LazyRoute permission='attributes' element={<AddAttribute />} />
              }
            />
          </Route>
          <Route path={products.categories.index}>
            <Route
              index
              element={
                <LazyRoute
                  permission='categories'
                  element={<EntityCategoriesPage type='product' />}
                />
              }
            />
            <Route
              path={products.categories.edit}
              element={
                <LazyRoute
                  permission='categories'
                  element={<EditCategoryPage />}
                />
              }
            />
          </Route>
          <Route
            path={products.comments}
            element={
              <LazyRoute permission='comments' element={<CommentsPage />} />
            }
          />
          <Route path={products.shipping_ways.index}>
            <Route
              index
              element={
                <LazyRoute
                  permission='shipping'
                  element={<ProductShippingWays />}
                />
              }
            />
            <Route
              path={products.shipping_ways.show}
              element={
                <LazyRoute
                  permission='shipping'
                  element={<ProductShippingWaysSingle />}
                />
              }
            />
            <Route
              path={products.shipping_ways.add}
              element={
                <LazyRoute
                  permission='shipping'
                  element={<ProductShippingWaysSingle />}
                />
              }
            />
          </Route>
        </Route>
        {/** Products */}
        {/** Orders */}
        <Route path={orders.index}>
          <Route
            index
            element={<LazyRoute permission='orders' element={<OrdersPage />} />}
          />
          <Route
            path={orders.edit}
            element={
              <LazyRoute permission='orders' element={<EditSingleOrder />} />
            }
          />
          <Route
            path={orders.show}
            element={
              <LazyRoute permission='orders' element={<ShowOrderPage />} />
            }
          />
        </Route>
        {/** Orders */}
        {/** Customers */}
        <Route path={customers.index}>
          <Route
            index
            element={
              <LazyRoute permission='users' element={<CustomersPage />} />
            }
          />
          <Route
            path={customers.show}
            element={
              <LazyRoute permission='users' element={<CustomerSinglePage />} />
            }
          />
        </Route>
        {/** Customers */}
        {/** Notifications */}
        <Route path={notifications.index}>
          <Route
            index
            element={
              <LazyRoute
                permission='notifications'
                element={<NotificationsPage />}
              />
            }
          />
          <Route
            path={notifications.add}
            element={
              <LazyRoute
                permission='notifications'
                element={<AddNotification />}
              />
            }
          />
        </Route>
        {/** Notifications */}
        {/** Illnesses Page */}
        <Route path={illnesses.index}>
          <Route index element={<Navigate to={illnesses.list} />} />
          <Route
            path={illnesses.list}
            element={
              <LazyRoute permission='posts' element={<IllnessesPage />} />
            }
          />
          <Route
            path={illnesses.add}
            element={
              <LazyRoute
                permission='posts'
                element={<PostTypeAdd pageType='illnesses' />}
              />
            }
          />
          <Route path={illnesses.tags.index}>
            <Route
              index
              element={
                <LazyRoute
                  permission='tags'
                  element={<TagsPage postType={"illnesses"} />}
                />
              }
            />
            <Route
              path={illnesses.tags.add}
              element={
                <LazyRoute
                  permission='tags'
                  element={<TagsSinglePage postType={"illnesses"} />}
                />
              }
            />
            <Route
              path={illnesses.tags.show}
              element={
                <LazyRoute
                  permission='tags'
                  element={<TagsSinglePage postType={"illnesses"} />}
                />
              }
            />
          </Route>

          <Route
            path={illnesses.show}
            element={
              <LazyRoute
                permission='posts'
                element={<PostTypeAdd pageType='illnesses' />}
              />
            }
          />
          <Route path={illnesses.categories.index}>
            <Route
              index
              element={
                <LazyRoute
                  permission='categories'
                  element={<EntityCategoriesPage type='illnesses' />}
                />
              }
            />
            <Route
              path={illnesses.categories.edit}
              element={
                <LazyRoute
                  permission='categories'
                  element={<EditCategoryPage pageType='illnesses' />}
                />
              }
            />
          </Route>
        </Route>
        {/** Illnesses Page */}
        {/** Blog */}
        <Route path={blog.index}>
          <Route index element={<Navigate to={blog.list} />} />
          <Route
            path={blog.add}
            element={
              <LazyRoute
                permission='posts'
                element={<PostTypeAdd metaField={blogMetaFields} />}
              />
            }
          />
          <Route
            path={blog.show}
            element={
              <LazyRoute
                permission='posts'
                element={<PostTypeAdd metaField={blogMetaFields} />}
              />
            }
          />
          <Route path={blog.categories.index}>
            <Route
              index
              element={
                <LazyRoute
                  permission='categories'
                  element={<EntityCategoriesPage type='blog' />}
                />
              }
            />
            <Route
              path={blog.categories.edit}
              element={
                <LazyRoute
                  permission='categories'
                  element={<EditCategoryPage pageType='blog' />}
                />
              }
            />
          </Route>

          <Route path={blog.tags.index}>
            <Route
              index
              element={
                <LazyRoute
                  permission='tags'
                  element={<TagsPage postType={"blog"} />}
                />
              }
            />
            <Route
              path={blog.tags.add}
              element={
                <LazyRoute
                  permission='tags'
                  element={<TagsSinglePage postType={"blog"} />}
                />
              }
            />
            <Route
              path={blog.tags.show}
              element={
                <LazyRoute
                  permission='tags'
                  element={<TagsSinglePage postType={"blog"} />}
                />
              }
            />
          </Route>
          <Route
            path={blog.comments}
            element={
              <LazyRoute
                permission='comments'
                element={<CommentsPage type='posts' />}
              />
            }
          />
          <Route
            path={blog.list}
            element={<LazyRoute permission='posts' element={<Blog />} />}
          />
        </Route>
        {/** Blog */}
        {/** Settings */}
        <Route path={settings.index}>
          <Route index element={<Navigate to={settings.roles.index} />} />
          <Route
            path={settings.home.index}
            element={
              <LazyRoute permission='admin' element={<SettingsHomePage />} />
            }
          />
          <Route path={settings.roles.index}>
            <Route
              index
              element={
                <LazyRoute permission='admin' element={<SettingsRolesPage />} />
              }
            />
            <Route
              path={settings.roles.add}
              element={
                <LazyRoute
                  permission='roles'
                  element={<SettingsRoleAddPage />}
                />
              }
            />

            <Route
              path={settings.roles.show}
              element={
                <LazyRoute
                  permission='roles'
                  element={<SettingsRoleAddPage />}
                />
              }
            />
          </Route>
          <Route path={settings.privileges.index}>
            <Route
              index
              element={
                <LazyRoute
                  permission='users'
                  element={<SettingsPriviligesUser />}
                />
              }
            />
            <Route
              path={settings.privileges.show}
              element={
                <LazyRoute
                  permission='users'
                  element={<SettingsPriviligesUserAddPage />}
                />
              }
            />
            <Route
              path={settings.privileges.add}
              element={
                <LazyRoute
                  permission='users'
                  element={<SettingsPriviligesUserAddPage />}
                />
              }
            />
          </Route>
        </Route>
        {/** Settings */}
        {/* Excel */}
        <Route path={excel.index}>
          <Route
            path={excel.products}
            element={
              <LazyRoute
                permission='products'
                element={<ProductsExcelImports />}
              />
            }
          />
          <Route
            path={blog.comments}
            element={
              <LazyRoute
                permission='comments'
                element={<CommentsPage type='posts' />}
              />
            }
          />
        </Route>
        {/* Excel */}
      </Route>
    </Routes>
  );
};

export default RouterConfig;
