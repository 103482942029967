import { TFunction } from "i18next"

export interface absolutePath {
  path: string
  title: string
}

const absoluteRoutes = (t: TFunction): absolutePath[] => [
  {
    path: "/dashboard",
    title: t("routes:dashboard")
  },
  {
    path: "/dashboard/illnesses",
    title: t("routes:illnesses_and_signs")
  },
  {
    path: "/dashboard/orders",
    title: t("routes:orders")
  },
  {
    path: "/dashboard/products",
    title: t("routes:products")
  },
  {
    path: "/dashboard/products/attributes",
    title: t("routes:attributes_management")
  },
  {
    path: "/dashboard/products/tags",
    title: t("routes:tags_management")
  },
  {
    path: "/dashboard/products/bundles",
    title: t("routes:product_bundles_listing")
  },
  {
    path: "/dashboard/products/categories",
    title: t("routes:categories")
  },
  {
    path: "/dashboard/products/shipping_ways",
    title: t("routes:shipping_ways")
  },
  {
    path: "/dashboard/products/packing",
    title: t("routes:product_packing")
  },
  {
    path: "/dashboard/blog",
    title: t("routes:blog")
  },
  {
    path: "/dashboard/supports",
    title: t("routes:supports")
  },
  {
    path: "/dashboard/blog/categories",
    title: t("common:categories")
  },
  {
    path: "/dashboard/blog/tags",
    title: t("common:tags")
  },
  {
    path: "/dashboard/blog/list",
    title: t("common:article_list")
  },
  {
    path: "/dashboard/settings",
    title: t("routes:settings")
  },
  {
    path: "/dashboard/settings/roles",
    title: t("routes:roles")
  },
  {
    path: "/dashboard/settings/privileges",
    title: t("routes:users_with_privileges")
  },
  {
    path: "/dashboard/orders/show",
    title: t("routes:order_details")
  }
]

export default absoluteRoutes
