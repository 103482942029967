import { GuaranteeDispatchTypes } from "./types"
import { Reducer } from "redux"

export type GuaranteeStateType = {
  items: any[]
}

const initial: GuaranteeStateType = {
  items: []
}

const reducer: Reducer<GuaranteeStateType, GuaranteeDispatchTypes> = (
  state = initial,
  action
) => {
  switch (action.type) {
    case "GUARANTEE_ADD":
      return {
        ...state,
        items: [...state.items, action?.payload]
      }
    case "GUARANTEE_REMOVE":
      return {
        ...state,
        items: state.items.filter((item, index) => index !== action.payload)
      }
    default:
      return state
  }
}

export default reducer
