import React from "react"
import { authSelectors } from "@/redux/auth"
import { routeNames } from "."
import { toaster } from "@/components/reusables/toaster"
import { useAppSelector } from "@/redux"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

type Props = {
  element: React.ReactNode
  permission?: string
}

/**
 * @param {React.ReactNode} element the component to be lazy loaded
 * @example `<Route path="path" element={<LazyRoute element={<HomePage />} />} />`
 */

const LazyRoute: React.FC<Props> = ({ element, permission }) => {
  const { t } = useTranslation(["common"])

  const userPermissions = useAppSelector(authSelectors.userPermissionsSelector)

  const navigate = useNavigate()

  React.useEffect(() => {
    if (permission) {
      if (!userPermissions.includes(permission)) {
        toaster.warning(t("common:you_dont_have_access_to_this_page"))
        navigate(routeNames.dashboard)
      }
    }
    return () => {}
  }, [permission])

  return (
    <React.Suspense
      fallback={<div>loading...</div> /* will add fallback later */}
    >
      {element}
    </React.Suspense>
  )
}

export default LazyRoute
