import {
  AddProductSteps,
  AttributeForVariant,
  CrudProductStateTypeInformation,
  IProductMeta
} from "./redcuer"

import { CategoryWithAttributes } from "@/pages/dashboard/products/categories/edit"
import { IEntityMedia } from "./services"
import { IProduct } from "@/types/product"
import { ProductVariant } from "@/pages/dashboard/products/add-product/variants-pricing"

export const CRUD_PRODUCT_SET = "CRUD_PRODUCT_SET"

interface CrudProductSetInformation {
  type: typeof CRUD_PRODUCT_SET
  payload: {
    value: IProduct
  }
}

export const CRUD_PRODUCT_SET_PRODUCT_MEDIAS = "CRUD_PRODUCT_SET_PRODUCT_MEDIAS"

interface CrudProductSetMedias {
  type: typeof CRUD_PRODUCT_SET_PRODUCT_MEDIAS
  payload: IEntityMedia[]
}

export const CRUD_PRODUCT_INIT = "CRUD_PRODUCT_INIT"
interface CrudProductInit {
  type: typeof CRUD_PRODUCT_INIT
}

export const CRUD_PRODUCT_CHANGE_GENERAL_INFORMATION =
  "CRUD_PRODUCT_CHANGE_GENERAL_INFORMATION"

interface CrudProductChangeGeneralInformation {
  type: typeof CRUD_PRODUCT_CHANGE_GENERAL_INFORMATION
  payload: {
    value: CrudProductStateTypeInformation
  }
}

export const CRUD_PRODUCT_CHANGE_STEP = "CRUD_PRODUCT_CHANGE_STEP"

interface CrudProductChangeStep {
  type: typeof CRUD_PRODUCT_CHANGE_STEP
  payload: AddProductSteps
}

export const CRUD_PRODUCT_SELECT_CATEGORY = "CRUD_PRODUCT_SELECT_CATEGORY"
interface CrudProductSelectCategory {
  type: typeof CRUD_PRODUCT_SELECT_CATEGORY
  payload: CategoryWithAttributes
}

export const CRUD_PRODUCT_SET_SELECTED_CATEGORIES =
  "CRUD_PRODUCT_SET_SELECTED_CATEGORIES"
interface CrudProductSetSelectedCategories {
  type: typeof CRUD_PRODUCT_SET_SELECTED_CATEGORIES
  payload: CategoryWithAttributes[]
}

export const CRUD_PRODUCT_SET_ATTRIBUTE = "CRUD_PRODUCT_SET_ATTRIBUTE"
interface CrudProductSetAttribute {
  type: typeof CRUD_PRODUCT_SET_ATTRIBUTE
  payload: {
    id: string
    value: string
  }
}

export const CRUD_PRODUCT_SET_ATTRIBUTES = "CRUD_PRODUCT_SET_ATTRIBUTES"
interface CrudProductSetAttributes {
  type: typeof CRUD_PRODUCT_SET_ATTRIBUTES
  payload: {
    id: string
    value: string[]
  }
}

export const CRUD_PRODUCT_SET_ATTRIBUTE_FOR_VARIANTS =
  "CRUD_PRODUCT_SET_ATTRIBUTE_FOR_VARIANTS"
interface CrudProductSetAttributeForVariants {
  type: typeof CRUD_PRODUCT_SET_ATTRIBUTE_FOR_VARIANTS
  payload: AttributeForVariant
}

export const CRUD_PRODUCT_SET_ATTRIBUTES_FOR_VARIANTS =
  "CRUD_PRODUCT_SET_ATTRIBUTES_FOR_VARIANTS"
interface CrudProductSetAttributesForVariants {
  type: typeof CRUD_PRODUCT_SET_ATTRIBUTES_FOR_VARIANTS
  payload: { [key: number]: AttributeForVariant }
}

export const CRUD_PRODUCT_SET_VARIANTS = "CRUD_PRODUCT_SET_VARIANTS"
interface CrudProductSetVaiants {
  type: typeof CRUD_PRODUCT_SET_VARIANTS
  payload: ProductVariant[]
}

export const CRUD_PRODUCT_SET_PRODUCT_IMAGES = "CRUD_PRODUCT_SET_PRODUCT_IMAGES"
interface CrudProductSetProductImages {
  type: typeof CRUD_PRODUCT_SET_PRODUCT_IMAGES
  payload: (string | File | null)[]
}

export const CRUD_PRODUCT_LOADING = "CRUD_PRODUCT_LOADING"
interface CrudProductDisableLoading {
  type: typeof CRUD_PRODUCT_LOADING
}

export const CRUD_PRODUCT_DELETE_PRODUCT_IMAGES =
  "CRUD_PRODUCT_DELETE_PRODUCT_IMAGES"
interface CrudProductDeleteImage {
  type: typeof CRUD_PRODUCT_DELETE_PRODUCT_IMAGES
  payload: number
}

export const CRUD_PRODUCT_META =
  "CRUD_PRODUCT_META"
interface CrudProductMeta {
  type: typeof CRUD_PRODUCT_META
  payload: IProductMeta
}

export type CrudProductDispatchTypes =
  | CrudProductChangeGeneralInformation
  | CrudProductChangeStep
  | CrudProductSetInformation
  | CrudProductSelectCategory
  | CrudProductSetSelectedCategories
  | CrudProductSetAttribute
  | CrudProductSetAttributeForVariants
  | CrudProductSetVaiants
  | CrudProductSetAttributes
  | CrudProductSetAttributesForVariants
  | CrudProductInit
  | CrudProductSetProductImages
  | CrudProductSetMedias
  | CrudProductDisableLoading
  | CrudProductDeleteImage
  | CrudProductMeta
