import NumberFormat from "react-number-format"

const NumberFormatCustom = (props: any) => {
  const { inputRef, onChange, prefix, ...other } = props

  return (
    <NumberFormat
      {...other}
      ref={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value
          }
        })
      }}
      thousandSeparator
      prefix={prefix}
      type={"text"}
    />
  )
}

export default NumberFormatCustom
