import { Navigate, useLocation, useNavigate } from "react-router-dom";

import React, { useEffect } from "react";
import RouteNames from "./routeNames";
import { authSelectors } from "@/redux/selectors";
import { useAppSelector } from "@/redux";
import { useDispatch } from "react-redux";
import { authActions } from "@/redux/auth";

type PrivateRouteProps = {
  element: React.ReactNode;
  permission?: string;
  role?: string;
};

/**
 * @param {React.ReactNode} element the component to be privated
 * @example `<Route path="path" element={<PrivateRoute element={<HomePage />} />} />`
 */
const PrivateRoute: React.FC<PrivateRouteProps> = ({
  element,
  permission,
  role,
}) => {
  const { login } = RouteNames;

  // Get the location of current page and redirect to it after login
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const isAuthenticated = useAppSelector(authSelectors.isAuthSelector);
  const userPermissions = useAppSelector(authSelectors.userPermissionsSelector);
  const user = useAppSelector(authSelectors.userSelector);
  const roles = user?.roles?.map((role) => role.name) || [];

  useEffect(() => {
    if (role && roles.length) {
      if (!roles.includes(role)) {
        dispatch(authActions.authLogout());
      }
    }
  }, [roles, role]);

  if (permission) {
    if (userPermissions.includes(permission)) {
      alert("You have access");
    } else {
      alert("You dont have access");
    }
  }

  /**
   * This function will return Route element based on user Authorization.
   * @returns Element our redirect to login page
   */

  return isAuthenticated ? (
    <>{element}</>
  ) : (
    <Navigate to={{ pathname: login }} state={{ from: location }} />
  );
};

export default PrivateRoute;
